import { createWebHashHistory, createRouter } from 'vue-router'
//import Dashboard from "@/views/dashboard";
//import Account from "@/views/account";
//import Order from "@/views/order";
// import Login from "@/views/login";


const routes = [
  { path: '/index', name:'/index', component:  () => import('@/views/index/index') , meta : { title: '' }, hidden : true, children : [
    { path: '/', name:'/', hidden : false ,component:  () => import('@/views/dashboard/index'), meta : { title: '主页' , icon : 'HomeFilled' }  },
    { path: '/account', name:'/account', hidden : false ,component:  () => import('@/views/account/index'), meta : { title: '拍单账号管理' , icon : 'UserFilled' }  },
    { path: '/shop', name:'/shop', hidden : false ,component:  () => import('@/views/shop/index'), meta : { title: '店铺管理' , icon : 'Shop' }  },
    { path: '/order2', name:'/order2', hidden : false ,component:  () => import('@/views/order/index2'), meta : { title: '订单管理' , icon : 'Tickets' }  },
    // { path: '/order', name:'/order', hidden : false ,component:  () => import('@/views/order/index'), meta : { title: '订单管理' , icon : 'Tickets' }  },
    { path: '/batch', name:'/batch', hidden : false ,component:  () => import('@/views/batch/index'), meta : { title: '任务管理' , icon : 'CaretRight' }  },
    { path: '/consign', name:'/consign', hidden : false ,component:  () => import('@/views/consign/index'), meta : { title: '快递管理' , icon : 'Van' }  },
    { path: '/user_share', name:'/user_share', hidden : true ,component:  () => import('@/views/user_share/index'), meta : { title: '分销返佣' , icon : 'CaretRight' }  },
    { path: '/score/log', name:'/score/log', hidden : false ,component:  () => import('@/views/log/score'), meta : { title: '点数记录' , icon : 'Calendar' }  },
    { path: '/express/log', name:'/express/log', hidden : false ,component:  () => import('@/views/log/express'), meta : { title: '运单记录' , icon : 'Calendar' }  },
    { path: '/recharge', name:'/recharge', hidden : true ,component:  () => import('@/views/recharge/index'), meta : { title: '点数充值' , icon : '' }  },

    { path: '/express', name:'/express', hidden : true ,component:  () => import('@/views/express/index'), meta : { title: '手动购买单号' , icon : '' }  },

  ] },

  {
    path: '/login', name:'/login', component:  () => import('@/views/login/index') , meta : { title: '用户登录' }, hidden : true
  },
  {
    path: '/register/:share_code?', name:'/register', component:  () => import('@/views/register/index') , meta : { title: '用户注册' }, hidden : true
  },

 
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})
export default {router,routes}